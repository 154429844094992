/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { Component, isValidElement } from 'react';
import {
  Dropdown,
  Overlay,
  Row,
  Box,
  Icon,
  CnTooltip,
  CnIcon,
} from '@alife/cn-ui';
import { isMobile as mobile } from 'react-device-detect';
import { SearchBar } from '../components/SearchBar';
import CustomerMenu from './../CustomerMenu';
import classnames from 'classnames';
import {
  PARK_API,
  checkIsCurrentMenu,
  getHashs,
  getPath,
  isZh,
  locale,
  preventDefault,
  redirectURL,
} from './../shared';
import { GuideBalloon } from './components';
// import { switchRegion } from '../services';
import './index.scss';
import Update from './components/update';
import { noSelectImg, selectImg } from '../MenuItem';
import ToDoList, { CnBalloonTrigger } from './components/ToDoList';

export const isMobile = mobile || window.innerWidth < 750;

const { Popup } = Overlay;

export default class EspCommonLayout extends Component {
  checkHasGrandson(list) {
    return list.some((son) => son.children && son.children.length);
  }
  render() {
    const {
      globalMenuTitle,
      globalMenuVisible,
      globalMenuVisibleChange,
      globalMenu = [],
      globalMenuClick,
      handleCheckPinned,
      customMenu = [],
      customMenuClick,
      langList = [],
      langVisible,
      currentLangInfo,
      setLangVisible,
      onLangChange,
      extraRender,
      expandTriggerType,
      isGetIntoPortal,
      setIsGetIntoPortal,
      ifCustomApi,
      helpVisible,
      setHelpVisible,
      downloadCenterUrl,
      noBoss,
      curItem,
      isInside,
      search,
      setSearch,
      searchConversationId,
      employeeId,
      currentPageProductCode,
      loadToDo,
    } = this.props;
    const renderSubPanelSamll = (dataSource, callback, menuCode, isFourNode) =>
      dataSource.map((item, index) => (
        <>
          <div
            className={classnames({
              'sub-panel-item': true,
              'sub-panel-item-weight': !isFourNode && item.children?.length,
              'sub-panel-item-padding-bottom':
                isFourNode && index === dataSource.length - 1,
              'sub-panel-item-link': !!item.menuUrl,
              'sub-panel-item-active':
                getHashs()?.includes(getPath(item.menuUrl)) ||
                (menuCode && item.menuCode === menuCode),
            })}
            key={item.key || index}
          >
            {isFourNode ? <i className='isFourNode'>—</i> : null}
            {!item.menuUrl ? (
              <span>{item.menuTitle}</span>
            ) : (
              <a
                href={item.menuUrl}
                onClick={(e) => {
                  preventDefault(item.menuUrl, e);
                  callback && callback(item);
                }}
              >
                {item.menuTitle}
              </a>
            )}
          </div>
          {!isFourNode && item.children?.length
            ? renderSubPanelSamll(item.children, callback, menuCode, true)
            : null}
        </>
      ));

    const renderSubPanel = (
      dataSource,
      callback,
      fatherItem,
      isDingMenu,
      setActive,
    ) => {
      const columnCount =
        fatherItem.columnCount ||
        (dataSource.length > 6 ? 6 : dataSource.length);
      return (
        <div
          className={`sub-panel-wrap ${
            dataSource.length > 6 ? 'sub-panel-wrap-noAuto' : ''
          }`}
          style={{
            width: dataSource.length > 6 ? '100%' : 'auto',
            left: '0!important',
          }}
        >
          <CnIcon
            x-if={!isDingMenu}
            type='close'
            size='medium'
            className='sub-panel-wrap-close'
            onClick={() => {
              if (isMobile) {
                setActive && setActive('');
              } else {
                globalMenuVisibleChange(false);
              }
            }}
          />
          <Box
            direction='row'
            wrap
            className='sub-panel-wrap-column'
            style={{
              width: '100%',
              columnCount,
              WebkitColumnCount: columnCount,
              MozColumnCount: columnCount,
              columnGap: fatherItem.columnGap || 20,
              WebkitColumnGap: fatherItem.columnGap || 20,
              MozColumnGap: fatherItem.columnGap || 20,
            }}
          >
            {dataSource.map((item, index) => (
              <div
                key={item.key || index}
                className='sub-panel-wrap-column-item'
              >
                <Box
                  className='sub-menu-item-title'
                  direction='row'
                  align='center'
                  justify='space-between'
                >
                  <Box
                    direction='row'
                    align='center'
                    style={{ flex: 1, maxWidth: item.isDing ? '88%' : '100%' }}
                  >
                    <a
                      href={item.menuUrl}
                      onClick={(e) => {
                        preventDefault(item.menuUrl, e);
                        callback(item);
                      }}
                      className={classnames({
                        ellipsis: true,
                        'ellipsis-link': !!item.menuUrl,
                        'ellipsis-active': getHashs()?.includes(
                          getPath(item.menuUrl),
                        ),
                      })}
                      style={{
                        marginRight: '6px',
                        cursor: item.menuUrl ? 'pointer' : '',
                        maxWidth: item.menuUrl ? 'calc(100% - 16px)' : '',
                      }}
                    >
                      {item.menuTitle}
                    </a>
                    {item.menuUrl ? (
                      <div className='item-icon-right'> </div>
                    ) : null}
                  </Box>
                  {item.isDing ? (
                    <Box
                      className='no-select'
                      justify='center'
                      style={{ marginLeft: 4 }}
                    >
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCheckPinned(item, !item.pinned);
                        }}
                      >
                        <img
                          src={item.pinned ? selectImg : noSelectImg}
                          alt='pinned'
                        />
                      </div>
                    </Box>
                  ) : null}
                </Box>
                {item.children &&
                  !!item.children.length &&
                  renderSubPanelSamll(item.children, callback)}
              </div>
            ))}
          </Box>
        </div>
      );
    };

    // // bucket切换
    // const onRegionChange = async (item) => {
    //   const regions = { 'zh-CN': 'CN_HANGZHOU', 'en-US': 'AP_SOUTHEAST_1' };
    //   const region = regions[item?.menuCode];
    //   if (!noBoss) {
    //     if (region) {
    //       await switchRegion(region);
    //     }
    //     onLangChange && onLangChange(item);
    //   } else {
    //     onLangChange && onLangChange(item, region);
    //   }
    // };

    return (
      <div
        style={{ position: 'relative' }}
        className='main-header main-header-black'
      >
        <Box direction='row' justify='space-between'>
          <Box align='center' direction='row'>
            <GuideBalloon
              ifCustomApi={ifCustomApi}
              isGetIntoPortal={isGetIntoPortal}
              updateIsGetIntoPortal={setIsGetIntoPortal}
            />
            <Popup
              trigger={
                <div style={{ zIndex: 1000 }}>
                  <CnTooltip
                    trigger={
                      <Box
                        className='nav-menu-icon no-select'
                        align='center'
                        justify='center'
                        style={{
                          background:
                            globalMenuVisible || !isGetIntoPortal
                              ? '#424344'
                              : 'transparent',
                        }}
                      >
                        <img
                          src='https://img.alicdn.com/imgextra/i3/O1CN01nMaS3w1W8LIY61M4M_!!6000000002743-55-tps-16-16.svg'
                          alt={locale.menu}
                        />
                      </Box>
                    }
                    popupStyle={{ marginTop: 10 }}
                    align='br'
                  >
                    {globalMenuTitle}
                  </CnTooltip>
                </div>
              }
              container={(trigger) => trigger}
              visible={globalMenuVisible}
              triggerType='click'
              onVisibleChange={(visible) => {
                if (!isGetIntoPortal || isGetIntoPortal === 'showEndBalloon') {
                  setIsGetIntoPortal('showEndBalloon');
                } else {
                  globalMenuVisibleChange(visible);
                }
              }}
              align='tl bl'
            >
              <div className='nav-custom-menu-wrap'>
                <div className='nav-custom-menu-listwrap'>
                  <CustomerMenu
                    dataSource={globalMenu}
                    onSelect={(it) => {
                      globalMenuClick(it);
                    }}
                    onTackSelect={(it, selected) => {
                      handleCheckPinned(it, selected);
                    }}
                    expandTriggerType={expandTriggerType}
                    renderSubPanel={renderSubPanel}
                    customMenuClick={customMenuClick}
                    globalMenuTitle={globalMenuTitle}
                  />
                </div>
              </div>
            </Popup>
            {/* logo */}
            <a
              href='/'
              className='no-select'
              style={{ textDecoration: 'none', height: '100%' }}
            >
              <Box
                className='header-logo no-select'
                justify='center'
                align='center'
                style={{ height: '100%' }}
              >
                <img
                  src='https://img.alicdn.com/imgextra/i3/O1CN01HWvK07292AzH72F8b_!!6000000008009-55-tps-65-24.svg'
                  alt='LOGO'
                  style={{ maxHeight: 28, marginTop: -3 }}
                />
              </Box>
            </a>
          </Box>
          {/* 中间菜单区 */}
          <Row className='main-header-custom-wrap' x-if={!isMobile}>
            <Box direction='row' className='main-header-custom'>
              {customMenu.map((item, index) => (
                <React.Fragment key={item.key || index}>
                  {item.children && item.children.length > 0 ? (
                    this.checkHasGrandson(item.children) ? (
                      <Popup
                        trigger={
                          <Box
                            className={classnames({
                              'custom-menu-item': true,
                              'custom-menu-item-active':
                                item.itemVisible || checkIsCurrentMenu(item),
                              'no-select': true,
                            })}
                            align='center'
                            justify='center'
                            onClick={() => {
                              customMenuClick(item);
                            }}
                          >
                            <a
                              href={item.menuUrl}
                              onClick={(e) => preventDefault(item.menuUrl, e)}
                            >
                              {item.menuTitle}
                              <Icon
                                type='arrow-down'
                                size='small'
                                className='custom-menu-item-arrow-down'
                                style={{ marginLeft: 4 }}
                              />
                            </a>
                          </Box>
                        }
                        container={(trigger) => trigger.parentNode}
                        triggerType={expandTriggerType}
                        // align='tc bc'
                        v2
                        placement='b'
                      >
                        {/* 宽100% */}
                        {renderSubPanel(
                          item.children || [],
                          customMenuClick,
                          item,
                          true,
                        )}
                      </Popup>
                    ) : (
                      <Popup
                        trigger={
                          <Box
                            className={classnames({
                              'custom-menu-item': true,
                              'custom-menu-item-active':
                                item.itemVisible || checkIsCurrentMenu(item),
                              'no-select': true,
                            })}
                            align='center'
                            justify='center'
                            onClick={() => {
                              customMenuClick(item);
                            }}
                          >
                            <a
                              href={item.menuUrl}
                              onClick={(e) => preventDefault(item.menuUrl, e)}
                            >
                              {item.menuTitle}
                              <Icon
                                type='arrow-down'
                                size='small'
                                className='custom-menu-item-arrow-down'
                                style={{ marginLeft: 4 }}
                              />
                            </a>
                          </Box>
                        }
                        container={(trigger) => trigger.parentNode.parentNode}
                        triggerType={expandTriggerType}
                        align='tc bc'
                      >
                        {/* 小菜单栏 */}
                        <Box className='sub-panel-wrap'>
                          {renderSubPanelSamll(
                            item.children || [],
                            customMenuClick,
                          )}
                        </Box>
                      </Popup>
                    )
                  ) : (
                    <Box
                      className={classnames({
                        'custom-menu-item': true,
                        'custom-menu-item-active':
                          item.menuUrl === '/' || checkIsCurrentMenu(item),
                        'no-select': true,
                      })}
                      align='center'
                      justify='center'
                    >
                      {/* 导航上 */}
                      <a
                        href={item.menuUrl}
                        onClick={(e) => {
                          preventDefault(item.menuUrl, e);
                          customMenuClick(item);
                        }}
                      >
                        {item.menuTitle}
                      </a>
                    </Box>
                  )}
                </React.Fragment>
              ))}
            </Box>
          </Row>
          {/* 右侧菜单 */}
          <Box direction='row' align='center'>
            {/* 搜索 */}
            {search ? (
              <Box
                x-if={isInside && isZh}
                className='nav-menu-icon no-select nav-menu-search'
                direction='row'
                align='center'
                justify='space-between'
              >
                <SearchBar
                  conversationId={searchConversationId}
                  placeholder={locale.searchCenter}
                  search={search}
                />
              </Box>
            ) : (
              <CnTooltip
                x-if={isInside && isZh}
                trigger={
                  <Box
                    className='nav-menu-icon no-select'
                    style={{ marginLeft: 0, marginRight: 0 }}
                    align='center'
                    justify='center'
                    onClick={() => setSearch((s) => !s)}
                  >
                    <img
                      src='https://img.alicdn.com/imgextra/i2/O1CN01ZabfU41TZPrjpQjan_!!6000000002396-55-tps-16-16.svg'
                      alt={locale.searchCenter}
                    />
                  </Box>
                }
                popupStyle={{ marginTop: 10 }}
              >
                {locale.searchCenter}
              </CnTooltip>
            )}
            {/* 下载中心 */}
            <CnTooltip
              x-if={!!downloadCenterUrl}
              trigger={
                <Box
                  className='nav-menu-icon no-select'
                  style={{ marginLeft: 0, marginRight: 0 }}
                  align='center'
                  justify='center'
                  onClick={() => {
                    const menuUrl =
                      downloadCenterUrl.substring(0, 1) === '/'
                        ? `${PARK_API}${downloadCenterUrl}`
                        : downloadCenterUrl;
                    redirectURL(menuUrl, downloadCenterUrl);
                  }}
                >
                  <img
                    src='https://img.alicdn.com/imgextra/i1/O1CN01h4Xrjb1hG5m7AwO4s_!!6000000004249-55-tps-16-16.svg'
                    alt={locale.downloadCenter}
                  />
                </Box>
              }
              popupStyle={{ marginTop: 10 }}
            >
              {locale.downloadCenter}
            </CnTooltip>
            {/* 我的待办 */}
            {!noBoss && loadToDo ? <ToDoList /> : null}
            <CnTooltip
              // 非park，都显示问题反馈
              // x-if={!window.location.origin.includes('park')}
              trigger={
                <CnBalloonTrigger>
                  <Box
                    className='nav-menu-icon no-select'
                    style={{ marginLeft: 0, marginRight: 0 }}
                    align='center'
                    justify='center'
                    onClick={() => setHelpVisible(true)}
                  >
                    {/* <img
                    src='https://img.alicdn.com/imgextra/i4/O1CN011rFqb529az5q9cgIC_!!6000000008085-55-tps-16-16.svg'
                    alt={locale.help}
                  /> */}
                    <CnIcon
                      type='help-fill'
                      style={{
                        color: '#fff',
                        width: 19,
                        fontSize: 19,
                      }}
                    />
                  </Box>
                </CnBalloonTrigger>
              }
              popupStyle={{ marginTop: 10 }}
            >
              {locale.help}
            </CnTooltip>
            {/* boss-帮助弹窗 */}
            {helpVisible ? (
              <Update
                noBoss={noBoss}
                helpVisible={helpVisible}
                onClose={() => setHelpVisible(false)}
                employeeId={employeeId}
                currentPageProductCode={currentPageProductCode}
              />
            ) : null}
            {/* park菜单配置的-帮助中心 */}
            <CnTooltip
              x-if={!!(curItem?.docName && curItem?.docUrl)}
              trigger={
                <Box
                  className='nav-menu-icon no-select'
                  style={{ marginLeft: 0, marginRight: 0 }}
                  align='center'
                  justify='center'
                  onClick={() => redirectURL(curItem.docUrl, curItem.docUrl)}
                >
                  <img
                    src='https://img.alicdn.com/imgextra/i3/O1CN01Zb3yZA1WS2KcaB6wd_!!6000000002786-55-tps-15-16.svg'
                    alt={curItem.docName}
                  />
                </Box>
              }
              popupStyle={{ marginTop: 10 }}
            >
              {curItem.docName}
            </CnTooltip>
            {/* 语言切换 */}
            <Dropdown
              align='tc bc'
              trigger={
                <div style={{ height: 64 }}>
                  <Box
                    className='nav-menu-icon no-select'
                    style={{ marginLeft: 0 }}
                    align='center'
                    justify='center'
                  >
                    <img
                      src={currentLangInfo.menuIcon}
                      alt={currentLangInfo.menuTitle}
                    />
                  </Box>
                </div>
              }
              popupStyle={{ marginTop: 10 }}
              visible={langVisible}
              onVisibleChange={setLangVisible}
              container={(trigger) => trigger.parentNode}
              triggerType='hover'
            >
              <Box className='sub-panel-wrap'>
                {renderSubPanelSamll(
                  langList,
                  onLangChange,
                  currentLangInfo.menuCode,
                )}
              </Box>
            </Dropdown>
            {/* 自定义功能区 */}
            {isValidElement(extraRender)
              ? extraRender
              : typeof extraRender === 'function' &&
                isValidElement(extraRender())
              ? extraRender()
              : null}
          </Box>
        </Box>
      </div>
    );
  }
}

EspCommonLayout.defaultProps = {
  currentLangInfo: {},
  setLangVisible: () => {},
  onLangChange: () => {},
  langVisible: false,
  globalMenuVisible: false,
  globalMenuVisibleChange: () => {},
  globalMenuClick: () => {},
  handleCheckPinned: () => {},
  customMenuClick: () => {},
  expandTriggerType: 'hover',
  globalMenuTitle: '全部菜单',
};
