/* eslint-disable @typescript-eslint/ban-ts-comment */
import cnRequest from '@alife/cn-request';
// @ts-ignore
import { cnOptions } from './../header/services';
export const axiosConfig = {
    baseURL: '/',
    timeout: 50000,
};
// export const cnOptions = {};
const request = cnRequest(axiosConfig, cnOptions);
export const newRequest = async (config) => {
    // @ts-ignore
    const resp = (await request(config));
    // @ts-ignore
    const { errorCode, errorMsg } = resp?.data || {};
    if (cnOptions.loginCallback(errorCode, errorMsg, resp))
        return null;
    return Promise.resolve(resp?.data || {});
};
