/**
 * 基础居中弹性盒
 */
export const baseFlex = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};
/**
 * 面包屑Wrapper
 */
export const breadcrumbWrapper = {
    margin: '12px 0 0 20px',
};
/**
 * 面包屑带链接项
 */
export const breadcrumbLinkItem = {
    cursor: 'pointer',
    color: 'var(--cainiaoBlue)',
};
