export var SearchType;
(function (SearchType) {
    /**
     * 空
     */
    SearchType[SearchType["NONE"] = 0] = "NONE";
    /**
     * 热词与最近搜索页
     */
    SearchType[SearchType["HOT"] = 1] = "HOT";
    /**
     * 推荐页
     */
    SearchType[SearchType["RECOMMEND"] = 2] = "RECOMMEND";
    /**
     * 结果页
     */
    SearchType[SearchType["RESULT"] = 3] = "RESULT";
})(SearchType || (SearchType = {}));
