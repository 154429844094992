import React, { useEffect, useState } from 'react';
import {
  CnIcon,
  CnBadge,
  CnBalloon,
  CnResult,
  CnBox,
  CnButton,
  CnLoading,
} from '@alife/cn-ui';
import { styled } from 'styled-components';
import { getTodoList } from '../../../services';
import { bossOrigin, locale } from '../../../shared';

export const CnBalloonTrigger = styled.div`
  i {
    opacity: 0.69;
  }
  &:hover i {
    opacity: 1;
  }
`;

const CardWrapper = styled.div`
  display: grid;
  grid: auto-flow 64px / 1fr;
  gap: 0px;
  line-height: 1;
  font-size: 14px;
  overflow: hidden auto;
  max-height: 320px;
  place-items: center stretch;
`;

const MCardWrapper = styled.div`
  height: 100%;
  border-top: 1px solid rgb(238, 238, 238);
  display: grid;
  grid: none / 1fr 70px;
  place-items: center stretch;
`;

const ImgIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  display: inline-block;
  margin: 0px 4px 0px 0px;
`;

const ApprovalTitleWrapper = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  color: rgb(39, 47, 61);
  text-overflow: ellipsis;
  max-width: 320px;
`;

const ExtraMsgBox = styled.div`
  font-size: 12px;
  color: rgb(93, 103, 122);
  line-height: 1;
  span {
    margin-right: 12px;
  }
`;

const getHandleOpenLink = (url) => () => !!url && window.open(url);

function ToDoList() {
  const [loading, setLoading] = useState(false);
  const [todoList, setTodoList] = useState([]);

  const loadTodoList = () => {
    setLoading(true);
    getTodoList()
      .then(setTodoList)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadTodoList();
  }, []);

  return (
    <CnBalloon
      trigger={
        <CnBalloonTrigger>
          <CnBox
            className='nav-menu-icon no-select'
            style={{ marginLeft: 0, marginRight: 0 }}
            align='center'
            justify='center'
          >
            <CnBadge count={todoList.length}>
              <CnIcon
                type='task-list'
                style={{
                  color: '#fff',
                  width: 18,
                  fontSize: 18,
                  transform: 'rotateY(180deg) scaleX(1.1)',
                  margin: 4,
                }}
              />
            </CnBadge>
          </CnBox>
        </CnBalloonTrigger>
      }
      popupStyle={{ marginTop: 10 }}
      title={
        <CnBox direction='row' justify='space-between' align='center'>
          <CnButton text type='primary' onClick={() => loadTodoList()}>
            {locale.reload}
          </CnButton>
          <h3 style={{ margin: 0, fontWeight: 500, fontSize: 14 }}>
            {locale.todo} ({todoList.length})
          </h3>
          <CnButton
            text
            type='primary'
            onClick={getHandleOpenLink(`${bossOrigin}/basic/#/todo-list`)}
          >
            {locale.viewAll} <CnIcon type='arrow-right' size='small' />
          </CnButton>
        </CnBox>
      }
      // triggerType='click'
      style={{
        maxWidth: 380,
        width: 380,
        minHeight: 240,
        padding: 16,
        marginTop: 0,
      }}
      align='br'
      v2
      closable={false}
      onVisibleChange={(visible) => {
        if (visible) {
          loadTodoList();
        }
      }}
    >
      <CnLoading visible={loading} style={{ display: 'block' }}>
        {todoList.length ? (
          <CardWrapper>
            {todoList.map((item) => (
              <MCardWrapper key={item.name}>
                <CnBox direction='row'>
                  <ImgIcon
                    alt=''
                    src='https://img.alicdn.com/imgextra/i3/O1CN01HvOfeP1XOCe517Amr_!!6000000002913-55-tps-31-29.svg'
                  />
                  <div style={{ margin: '0px 0px 0px 4px' }}>
                    <ApprovalTitleWrapper onClick={getHandleOpenLink(item.url)}>
                      {item.name}
                    </ApprovalTitleWrapper>
                    <ExtraMsgBox>
                      <span>
                        {locale.applicantName}
                        {item.creatorName}
                      </span>
                      <span>{item.gmtCreateName}</span>
                    </ExtraMsgBox>
                  </div>
                </CnBox>
              </MCardWrapper>
            ))}
          </CardWrapper>
        ) : (
          <CnResult
            size='mini'
            type='empty'
            title={
              <CnBox
                direction='row'
                justify='center'
                align='center'
                style={{ color: '#999', fontSize: 12, fontFamily: 'Roboto' }}
              >
                {locale.noToDo}
                <CnButton
                  text
                  type='primary'
                  onClick={getHandleOpenLink(`${bossOrigin}/basic/#/todo-list`)}
                >
                  {locale.viewAllToDo}
                </CnButton>
              </CnBox>
            }
            subTitle=''
          />
        )}
      </CnLoading>
    </CnBalloon>
  );
}

export default ToDoList;
