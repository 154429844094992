export default {
    login: 'Login',
    downloadCenter: 'Download Center',
    workerNo: 'User ID: ',
    loginOut: 'Exit',
    workbench: 'My Vision',
    add: 'Add',
    fetchErrorMessage: 'Operation is too frequent. Please try again later',
    delete: 'Delete',
    more: 'More',
    globalMenuTitle: 'All menus',
    menu: 'Menu',
    parkOperation: 'Park operation',
    parkConstruction: 'Park construction',
    confirm: 'Confirm',
    fetchMenuErrorMessage: 'Error in global menu query!',
    fetchDingErrorMessage: 'Failed to query nails!',
    dingErrorMessage: 'Nail failed!',
    help: 'Problem feedback',
    estateAsst: 'Smart Assistant V',
    morning: 'Good Morning',
    afternoon: 'Good Afternoon',
    dou: ', ',
    mao: ': ',
    pleaseTickets: '. Please submit your problem here ~',
    ticketCenter: 'Work Order Center',
    viewTickets: "Click here to view work order you've submitted",
    newTicket: 'New Work Order',
    deadline: 'Deadline',
    problemDescription: 'Problem Description',
    wordLimit500: '500-word limit ',
    problemUrl: 'URL',
    problemPhoto: 'Photo',
    cancel: 'Cancel',
    submit: 'Submit',
    thereAre: 'There are',
    thereAres: 'There are',
    ticketConfirm: 'work order to confirm~ ',
    ticketSolving: 'work order solving~ ',
    ticketClosed: 'work order closed~ ',
    search: 'Search',
    toConfirm: 'To confirm',
    solving: 'Solving',
    closed: 'Closed',
    createTime: 'Create Time',
    solution: 'Solution',
    supplyInfo: 'Supply Info. ',
    supplyInfoNote: 'Note: After you supply information, the work order will be redirected to the technical staff for resolution. ',
    supplement: 'Supplement',
    photos: 'Photo(s)',
    handler: 'Handler',
    ding: 'Ding',
    close: 'Close Work Order',
    rectified: 'RECTIFIED',
    closedStatus: 'CLOSED',
    reopen: 'Reopen',
    empty: 'Empty',
    agreeInstruction: 'I have received it. ',
    closeInstruction: 'I confirm it can be closed. ',
    nodeDTOListEmpty: 'This workOrder flow nodes have not been configured. Please contact admin to handle it before saving it. ',
    saveProblemMsg: 'Save successfully! ',
    agreeProblemMsg: 'Confirm successfully! ',
    returnProblemMsg: 'Supply info. successfully! ',
    dingProblemMsg: 'Ding successfully! ',
    closeProblemMsg: 'Close successfully! ',
    reApplyProblemMsg: 'Reopen successfully! ',
    siteMgmt: 'Site Mgmt. ',
    costMgmt: 'Cost Mgmt. ',
    rentMgmt: 'Rent Mgmt. ',
    engineeringMgmt: 'Engineering Mgmt. ',
    apiErrorTitle: 'ERROR',
    apiErrorCode: 'ERROR CODE: ',
    traceId: 'Trace ID',
    apiErrorTraceID: 'Trace ID: ',
    copyApiErrorTraceID: 'Trace ID Copied',
    helpEstateAsst: 'NEED HELP',
    problemUrlPlaceholder: 'Enter the URL of this page.',
    problemDescriptionPlaceholder: 'You can report metrics that need attention on the MyVision workbench, and you can also report any issues you encounter in the system. We will arrange for a specialist to handle your request.',
    traceIdPlaceholder: 'If present, enter Trace ID.',
    searchCenter: 'Search',
    pastePlaceholder: 'Please paste the image here to upload',
    product: 'WIN Product',
    robotTitle: 'Robot',
    robotEstateAsst: 'Smart Assistant',
    robotPleaseTickets: '. If there are any issues, please contact Cainiao Intelligent Customer Service',
    problemType: 'Problem type',
    applicantName: 'Name of initiator：',
    reload: 'Reload',
    todo: 'My To Do',
    viewAll: 'View All',
    noToDo: 'No unfinished tasks，',
    viewAllToDo: 'View all pending tasks',
};
