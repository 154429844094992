/* eslint-disable react/prop-types */
import { Box, CnCard } from '@alife/cn-ui';
import { formatNumber } from './utils';
import { useEffect } from 'react';

export default (props) => {
  // eslint-disable-next-line react/prop-types
  const { params, setParams, search, noFecth, tab, initValues, loading } =
    props;
  useEffect(() => {
    if (noFecth) {
      const index = tab.findIndex((item) => item.number);
      if (index !== -1) {
        const p = {
          ...params,
          [tab[index].value]: true,
          fecthTab: false,
        };
        search(p, true);
        setParams(p);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noFecth, tab]);

  return (
    <CnCard loading={loading}>
      <Box direction='row' spacing={8}>
        {tab.map((item) => {
          const active = params[item.value];
          return (
            <Box
              style={{ flex: 1 }}
              onClick={() => {
                const p = {
                  ...params,
                  ...initValues,
                  [item.value]: true,
                  fecthTab: true,
                };
                setParams(p);
                search(p);
              }}
            >
              <CnCard
                style={{
                  marginBottom: 0,
                  cursor: 'pointer',
                  border: `1px solid ${active ? '#536fcc' : '#e7e8ed'}`,
                  boxShadow: active
                    ? '0 4px 10px 0 rgba(32,60,153,.2)'
                    : 'none',
                }}
              >
                <Box
                  style={{
                    fontSize: 14,
                    color: '#666',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                  }}
                >
                  {item.label}
                </Box>
                <Box
                  style={{
                    fontSize: 28,
                    color: '#1a1a1a',
                    fontWeight: 600,
                    textAlign: 'center',
                  }}
                >
                  {formatNumber(item.number, '- -')}
                </Box>
              </CnCard>
            </Box>
          );
        })}
      </Box>
    </CnCard>
  );
};
