export const searchResTypeMap = new Map()
    .set('ALL', {
    label: '全部',
})
    .set('CARD', {
    label: '卡片',
})
    .set('MENU', {
    label: '菜单',
})
    .set('REPORT', {
    label: '报表',
})
    .set('CONTRACT', {
    label: '招商合同',
})
    .set('BUSINESS_APPLY', {
    label: '商务条件申请',
})
    .set('SECURITY', {
    label: '安全隐患',
})
    .set('RISK', {
    label: '风险',
})
    .set('PARK_REFORM', {
    label: '园区改造',
});
export const matterTitleList = [
    { label: '待办清单', value: 'PENDING' },
    { label: '我发的任务', value: 'CREATE' },
    { label: '已办事项', value: 'COMPLETE' },
];
export const showTypeList = [
    { label: '菜鸟', value: 'CN' },
    { label: 'WLCC', value: 'wlcc' },
];
export const rentTypeList = [
    { label: '综合', value: '综合' },
    { label: '仓', value: '仓' },
    { label: '配', value: '配' },
];
