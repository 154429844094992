/**
 * 获取一个随机字符串
 * @param num
 * @returns
 */
export const getRadomStr = (num = 4) => Math.random()
    .toString(36)
    .slice(2, 2 + num);
/**
 * 新窗口打开链接
 * @param url
 * @returns
 */
export const handleOpenLink = (url) => !!url && open(url);
/**
 * 获取外部链接跳转处理
 * @param url
 * @returns
 */
export const getHandleOpenLink = (url) => () => !!url && open(url);
/**
 * 数组求和
 * @param list
 */
export const arraySum = (list) => {
    if (Array.isArray(list) && list.length !== 0) {
        return list.reduce((a, b) => {
            const temp = Number(b);
            return a + (!Object.is(temp, NaN) ? temp : 0);
        }, 0);
    }
    else {
        return 0;
    }
};
/**
 * 过滤接口返回内容
 * @param {*} res
 * @returns
 */
export function handleResult({ success, data, errorMsg }) {
    if (!success) {
        throw new Error(errorMsg);
    }
    return data;
}
export const handleAddQuery = (url, searchObj) => {
    const query = Object.entries(searchObj)
        .map((item) => item.join('='))
        .join('&');
    return url.includes('?') ? `${url}&${query}` : `${url}?${query}`;
};
/**
 * 数值转换（除，默认除 10000）
 * @param number
 * @param num
 * @returns
 */
export const divide = (number, num = 10000) => Number((number / num).toFixed(2));
/**
 * 根据单位转换数值
 * @param value
 * @param unit
 * @param withUnit
 * @returns
 */
export const convertValueWithUnit = (value, unit) => {
    if (value === undefined || value === null) {
        return '-';
    }
    if (unit && ['万元', '万'].includes(unit)) {
        return divide(value, 10000);
    }
    if (unit === '%') {
        return divide(value, 0.01);
    }
    return value;
};
export const handleMeta = (meta) => {
    // eslint-disable-next-line guard-for-in
    for (const key in meta) {
        if (meta[key].unit === '%') {
            // meta[key] = {
            //   ...meta,
            //   formatter: (value: number) => `${convertValueWithUnit(value, '%')}%`,
            // };
            // eslint-disable-next-line no-param-reassign
            meta[key].formatter = (value) => `${convertValueWithUnit(value, '%')}%`;
        }
        if (meta[key].unit && ['万元', '万'].includes(meta[key].unit)) {
            // meta[key] = {
            //   ...meta,
            //   formatter: (value: number) => `${convertValueWithUnit(value, '万元')}万元`,
            // };
            // eslint-disable-next-line no-param-reassign
            meta[key].formatter = (value) => `${convertValueWithUnit(value, '万元')}${meta[key].unit}`;
        }
    }
    return meta;
};
/**
 * 获取页面滚动容器
 * @returns
 */
export const getPageScrollBox = () => document.querySelector('.view-content');
/**
 * 像素转 vw
 * @param px
 * @returns
 */
export const vw = (px = 0) => {
    return `${(px / 1440) * 100}vw`;
};
