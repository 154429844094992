import React from 'react';
import { CnDialog, CnTagCloseable, CnTagGroup } from '@alife/cn-ui';
import { MAX_PINNED_LENGTH } from '../shared/const';
export const PinDialog = ({ pinnedMenuList, show: showDialog, operateItem, onClose, onConfirmPin, }) => {
    const canPin = pinnedMenuList.length < MAX_PINNED_LENGTH;
    return (React.createElement(CnDialog, { title: `当前导航菜单已满 ${MAX_PINNED_LENGTH} 个`, visible: showDialog, okProps: { disabled: !canPin }, onOk: () => {
            if (operateItem) {
                onConfirmPin(operateItem, true);
                onClose();
            }
        }, onCancel: onClose, onClose: onClose },
        React.createElement("div", null, `导航空间最多可展示 ${MAX_PINNED_LENGTH} 个菜单，如需添加新菜单，请选择当前导航上的已有菜单任意一个进行替换。`),
        React.createElement("div", null,
            "\u5982\u9700\u6DFB\u52A0\uFF0C\u8BF7\u81F3\u5C11\u53D6\u6D88\u4EE5\u4E0B\u5176\u4E2D\u4E00\u4E2A\uFF0C\u70B9\u51FB\u786E\u8BA4\u540E\u5373\u53EF\u6DFB\u52A0",
            React.createElement("span", { style: {
                    margin: '0 4px',
                    color: '#3D84FF',
                } }, operateItem?.menuTitle),
            "\u83DC\u5355\u3002"),
        React.createElement(CnTagGroup, { style: {
                marginTop: 16,
            } }, pinnedMenuList.map((item) => (React.createElement(CnTagCloseable, { key: item.menuCode, onClose: () => {
                onConfirmPin(item);
                return false;
            } }, item.menuTitle))))));
};
