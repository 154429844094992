import { Box } from '@alife/cn-ui';
import React from 'react';
const noContentImg = 'https://img.alicdn.com/imgextra/i4/O1CN01bkhh1m21HH4wlsyY9_!!6000000006959-55-tps-96-64.svg';
const noContentImgDark = 'https://img.alicdn.com/imgextra/i1/O1CN01A8j5NM26FVeLRKiQo_!!6000000007632-55-tps-160-104.svg';
const noAccessImg = 'https://img.alicdn.com/imgextra/i4/O1CN01oQNWzR1rY5L7fdHcH_!!6000000005642-55-tps-160-160.svg';
export const NoContent = ({ direction = 'row', message = '无内容', style, className, isDark, }) => {
    return (React.createElement(Box, { direction: direction, justify: 'center', align: 'center', spacing: 20, className: className, style: {
            transform: 'translateY(20px)',
            ...style,
        } },
        React.createElement("img", { src: isDark ? noContentImgDark : noContentImg, style: {
                width: 120,
                height: 120,
                objectFit: 'contain',
            } }),
        React.createElement("span", { style: {
                fontSize: 14,
                fontWeight: 700,
                color: '#6D7A90',
            } }, message)));
};
export const NoAssess = ({ direction = 'row', message = '暂无权限', style, className, }) => {
    return (React.createElement(Box, { direction: direction, justify: 'center', align: 'center', spacing: 20, className: className, style: {
            ...style,
        } },
        React.createElement("img", { src: noAccessImg, style: { width: 120, height: 120, objectFit: 'contain' } }),
        React.createElement("span", { style: {
                fontSize: 14,
                fontWeight: 700,
                color: '#6D7A90',
            } }, message)));
};
