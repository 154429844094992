/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Balloon } from '@alife/cn-ui';
import './index.scss';

const leftMenuImg =
  'https://img.alicdn.com/imgextra/i3/O1CN01Uvfo2c23Kb4v4nYqo_!!6000000007237-2-tps-960-1260.png';
const balloonImg =
  'https://img.alicdn.com/imgextra/i2/O1CN01EaMjbR1UbXAl0sqYm_!!6000000002536-2-tps-1446-762.png';

const GuideBalloon = (props) => {
  const [showStartBalloon, setShowStartBalloon] = useState(false);
  const [showEndBalloon, setShowEndBalloon] = useState(false);
  useEffect(() => {
    const isGetIntoPortal = window.localStorage.getItem('isGetIntoPortal');
    if (props.isGetIntoPortal === 'showEndBalloon') {
      setShowEndBalloon(true);
      setShowStartBalloon(false);
    } else if (!isGetIntoPortal) {
      // setShowStartBalloon(true);
    }
  }, [props.isGetIntoPortal]);
  return (
    <>
      <Balloon
        visible={showStartBalloon}
        triggerType='click'
        className='guide-tooltip-header'
        id='guide-tooltip-header'
        trigger={
          <div style={{ height: 64, position: 'relative', left: 32 }}>
            <div
              className='guide-tooltip-startBalloon'
              style={{ display: showStartBalloon ? 'block' : 'none' }}
            />
          </div>
        }
        align='br'
        popupContainer={(trigger) => trigger.parentNode}
        popupStyle={{ width: 286 }}
      >
        <div className='guide-tooltip-content'>
          <div className='guide-tooltip-content-title'>
            点击这里展开，查看所有菜单！
          </div>
          <div className='guide-tooltip-content-footer'>
            <span
              onClick={() => {
                props.updateIsGetIntoPortal('showEndBalloon');
              }}
            >
              知道了
            </span>
          </div>
        </div>
      </Balloon>
      <div
        className='guide-tooltip-endBalloon'
        style={{ display: showEndBalloon ? 'block' : 'none' }}
      >
        <Balloon
          visible={showEndBalloon}
          triggerType='click'
          className='guide-tooltip-header'
          id='guide-tooltip-header'
          trigger={<img src={leftMenuImg} width='281px' />}
          align='rb'
          popupContainer={(trigger) => trigger.parentNode}
          popupStyle={{ maxWidth: 620, width: 513 }}
          offset={[0, -74]}
        >
          <div className='guide-tooltip-content'>
            <div className='guide-tooltip-content-title'>
              点击固钉，自由固定常用模块导航，再次点击可取消
            </div>
            <div
              className='guide-tooltip-content-title'
              style={{ color: '#3076FC', marginTop: 9 }}
            >
              最多可钉{props.ifCustomApi ? 8 : 6}个哦！
            </div>
            <div className='guide-tooltip-content-img'>
              <img src={balloonImg} width='481px' />
            </div>
            <div
              className='guide-tooltip-content-footer'
              style={{ marginTop: 16 }}
            >
              <span
                onClick={() => {
                  window.localStorage.setItem(
                    'isGetIntoPortal',
                    'noFirstGetIntoPortal',
                  );
                  props.updateIsGetIntoPortal('noFirstGetIntoPortal');
                  setShowEndBalloon(false);
                }}
              >
                知道了
              </span>
            </div>
          </div>
        </Balloon>
      </div>
    </>
  );
};

export default GuideBalloon;
