import { newRequest } from './request';
import { handleResult } from '../utils/util';
export class WorkbenchService {
    /**
     * 获取热词数据
     * @returns
     */
    static async getHotDataList() {
        const resp = handleResult(await newRequest({
            url: '/search/recentAndHot',
            method: 'POST',
            data: {},
        }));
        return resp;
    }
    /**
     * 获取推荐数据
     * @returns
     */
    static async getRecommendDataList(data) {
        const resp = handleResult(await newRequest({
            url: `/search/suggest`,
            method: 'POST',
            data: { input: data.keyWord || '' },
        }));
        return resp;
    }
    /**
     * 获取所有的展示tab
     * @returns
     */
    static async getSearchResType() {
        const resp = handleResult(await newRequest({
            url: `/search/queryAllType`,
            method: 'POST',
            data: {},
        }));
        return resp;
    }
    /**
     * 获取查询数据
     * @returns
     */
    static async getSearchDataList(data) {
        const resp = handleResult(await newRequest({
            url: `/search/searchAll`,
            method: 'POST',
            data: {
                pageSize: 50,
                input: data.keyWord || '',
                type: data.type || undefined,
            },
        }));
        return resp;
    }
    /**
     * 获取查询数据（分页）
     * @returns
     */
    static async getSearchDataListByType(data) {
        const resp = handleResult(await newRequest({
            url: `/search/searchByType`,
            method: 'POST',
            data: {
                input: data.keyWord || '',
                type: data.type || undefined,
                page: data.page || undefined,
                pageSize: data.pageSize,
            },
        }));
        return resp;
    }
}
