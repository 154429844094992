import { CnMessage, CnImageViewer } from '@alife/cn-ui';
import cr from '@alife/cn-request';
import OSS from 'ali-oss';
import dayjs from 'dayjs';
// eslint-disable-next-line import/no-cycle
import { cnOptions } from '../../services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { bossOrigin, proxyOuterDomain } from '../../shared';
import LongText from './lone-text';

export const axiosConfig = {
  baseURL: '/',
  timeout: 50000,
  withCredentials: true,
};

const request = cr(axiosConfig, { ...cnOptions, turnOffLog: true });

// 请求的resolve处理
function resolveHandler(res, opt, returnFristData) {
  let response = res;
  // hideLoading(opt)
  if (!response) return;
  // 兼容dwork-data项目页面，res直接返回的data
  if (!response.headers) {
    response = { data: response };
  }
  const { data } = response;
  // 未登录执行登录回调
  if (cnOptions.loginCallback(data.errorCode, data.errorMsg, response))
    return null;
  if (opt.isHandle) return Promise.resolve(response);
  if (opt.isHandleSuccess) return Promise.resolve(data);
  if (!data) {
    if (opt.isHandleError) {
      return Promise.reject(response || {});
    }
    CnMessage.error(`网络错误，请稍后再试！[${response.status}]`);
    return Promise.resolve(null);
  }
  // 这里逻辑可以根据自己业务接口字段进行修改适配
  if (data.code === '2000' && proxyOuterDomain(data.msg)) return;
  if (data.errorCode === '1000' && proxyOuterDomain(data.errorMsg)) return;
  // if (data.resultMessage === 'nologinin' && proxyOuterDomain(data.data, true))
  //   return
  if (data.code === '0000') {
    if (data.msg === '调用成功') data.msg = '';
    if (data.data instanceof Array || returnFristData) {
      return data.data;
    }
    // 业务错误处理
    const {
      success,
      errorMessage,
      module,
      data: moduleData,
      message,
      errorMsg,
    } = typeof data.data === 'object' ? data.data : {};
    if (!success) {
      CnMessage.error(errorMsg || errorMessage || data.msg || message);
    } else if (module) {
      return Promise.resolve(module);
    } else if (moduleData) {
      return Promise.resolve(moduleData);
    }
  }
  if (data.success && data.data) {
    return Promise.resolve(data.data);
  }
  if (opt.isHandleError) {
    return Promise.reject(response.data || {});
  }
  if (response.data && response.data.message) {
    if (opt.isHandleError) {
      return Promise.reject(response.data);
    }
    CnMessage.error(response.data.message);
    return Promise.resolve(null);
  }
  return Promise.reject(opt.isHandleError ? response.data : {});
}

// 请求的reject处理
function rejectHandler(error, opt) {
  // hideLoading(opt)
  // 请求丢失时触发
  const emptyError = {
    data: null,
  };
  const response = error.response || emptyError;
  if (error.message === 'Network Error') {
    error.message = '网络错误，请稍后再试！';
    if (!response.data) response.data = {};
    response.data.Message = '网络错误，请稍后再试！';
  }
  if (opt.isHandleError) {
    return Promise.reject(response.data || {});
  }
  if (response.data && response.data.Message) {
    setTimeout(() => {
      // hideLoading(opt)
      CnMessage.error(response.data.Message);
    }, 0);
    return Promise.resolve(null);
  }
  if (error.message) {
    setTimeout(() => {
      // hideLoading(opt)
      CnMessage.error(error.message);
    }, 0);
    return Promise.resolve(null);
  }
  // isHandleError表示是否自定义错误处理
  return Promise.reject(opt.isHandleError ? response.data || {} : {});
}

export const cnRequest = (options, returnFristData) => {
  // eslint-disable-next-line no-param-reassign
  if (!options.headers) options.headers = {};
  // eslint-disable-next-line no-param-reassign
  options.headers.href = window.location.href;
  const out = request(options)
    .then(
      (res) => resolveHandler(res, options, returnFristData),
      (error) => rejectHandler(error, options),
    )
    .catch((err) => {
      // 这里自行定制逻辑，可删除
      // eslint-disable-next-line no-param-reassign
      console.error(err);
      return Promise.reject(err);
    });

  return out;
};

export const env =
  // eslint-disable-next-line no-nested-ternary
  location.hostname.includes('test') ||
  location.hostname.includes('daily') ||
  location.hostname.includes('localhost') ||
  location.hostname.includes('127.0.0.1')
    ? 'test'
    : location.hostname.includes('pre')
    ? 'pre'
    : 'pro';

export const envCode = env === 'test' ? 'testing' : 'production';

export const uuid = () => {
  const s = 'xxxx-xxxx-4xxx-uxxx-xxxx';
  return s.replace(/[xu]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const result = c === 'x' ? r : (r & 0x3) | 0x8;
    return String(result);
  });
};

const identifyUrl = `${bossOrigin}/basic/document/identify`;
/**
 * 断点续传
 * @param option 上传参数
 * @returns abort 取消上传方法
 */
export const multipartUpload = (
  fileSize = 1024,
  menuCode,
  url = identifyUrl,
  option,
) => {
  const ret = {
    abort() {},
  };

  const { file, editor } = option;
  const { size } = file;
  if (size > fileSize * 1024 * 1024) {
    return Promise.reject();
  }
  // const suffix = file.name.substring(file.name.lastIndexOf('.'));
  const storageKey = `${file.name}-${file.size}-${file.lastModified}`;
  // 检查 localStorage 里有没有断点数据
  let checkpointData = window.localStorage.getItem(storageKey);
  let ossKey; // 单个文件项唯一标识
  let checkpoint; // 断点数据
  if (checkpointData) {
    try {
      checkpointData = JSON.parse(checkpointData);
      ossKey = checkpointData.ossKey;
      checkpoint = checkpointData.value;
      checkpoint.file = file; // 把文件塞到断点
    } catch (error) {
      // noop
    }
  } else {
    ossKey = `${envCode}/${menuCode || window.MENUCODE || 'test'}/${uuid()}/${
      file.name
    }`;
    // ossKey = uuid() + suffix
  }
  file.ossKey = ossKey;
  cnRequest({
    url,
    method: 'POST',
    data: {
      roleSessionName: 'PutRole',
      ossKey,
    },
  })
    .then((data) => {
      if (data) {
        return new OSS({
          accessKeyId: data.accessKeyId,
          accessKeySecret: data.accessKeySecret,
          stsToken: data.securityToken,
          region: `oss-${data.region}`,
          // region: data.region,
          bucket: data.bucket,
          // cname: true,
          // endpoint: data.bucket + "." + data.endpoint,
        });
      }
      throw new Error(data);
    })
    .then(
      async (client) => {
        option.onCreate && option.onCreate(client);
        ret.abort = () => {
          client.cancel();
        };
        // 图片base64 → blob对象 → arrayBuffer对象 → buffer对象 → 成功上传
        let bufferFile = file;
        const getFile = (files) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(files);
            reader.onload = function (event) {
              const buffer = new OSS.Buffer(event.target.result);
              resolve(buffer);
            };
          });
        };
        // 兼容富文本粘贴的base64图片上传
        if (editor) {
          bufferFile = await getFile(bufferFile);
        }
        client
          .multipartUpload(ossKey, bufferFile, {
            checkpoint,
            progress: (p) => {
              option.onProgress && option.onProgress({ percent: p * 100 });
            },
          })
          .then(async ({ res }) => {
            if (res.statusCode === 200) {
              const getData = await cnRequest({
                url: identifyUrl,
                method: 'POST',
                data: {
                  roleSessionName: 'GetRole',
                },
              });
              const getClient = await new OSS({
                accessKeyId: getData.accessKeyId,
                accessKeySecret: getData.accessKeySecret,
                stsToken: getData.securityToken,
                region: `oss-${getData.region}`,
                bucket: getData.bucket,
              });
              const signatureUrl = getClient.signatureUrl(ossKey);
              option.onSuccess &&
                option.onSuccess({
                  url: signatureUrl,
                  success: true,
                  imgURL: signatureUrl,
                  downloadURL: signatureUrl,
                  ossKey,
                  name: file.name,
                });
            } else {
              throw new Error(res.message);
            }
          })
          .catch((error) => {
            window.localStorage.clear();
            // eslint-disable-next-line no-console
            console.error(error);
            if (error.name !== 'cancel') {
              option.onError && option.onError(error);
            }
          });
      },
      (error) => {
        option.onError && option.onError(error);
      },
    );

  return ret;
};

// 判断是否是图片
export const isImg = (name) => {
  const suffix = name?.toLowerCase();
  return (
    suffix.indexOf('.gif') !== -1 ||
    suffix.indexOf('.svg') !== -1 ||
    suffix.indexOf('.jpg') !== -1 ||
    suffix.indexOf('.png') !== -1 ||
    suffix.indexOf('.jpeg') !== -1
  );
};

/**
 * 数字千分位
 * @param {*} num
 */
export const formatNumber = (num, empty) => {
  if (isNaN(+num)) {
    return num || empty;
  }
  let strNum = num.toString();
  const DIGIT_PATTERN = /(^|\s)\d+(?=\.?\d*($|\s))/g;
  const MILLI_PATTERN = /(?=(?!\b)(\d{3})+$)/g;
  const sign = strNum.indexOf('-') !== -1 ? '-' : '';
  strNum = strNum.replace('-', '');

  return (
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    sign +
    strNum.replace(DIGIT_PATTERN, (m) => {
      return m.replace(MILLI_PATTERN, ',');
    })
  );
};

/**
 * 格式化时间日期
 * @param {String} value 时间戳
 * @returns {String}
 */
export const formatDate = (value) => {
  if (!value) {
    return '';
  }
  return dayjs(value).format('YYYY-MM-DD');
};

/**
 * 格式化日期 时间
 * @param {String} value 时间戳
 * @returns {String}
 */
export const formatDateTime = (value) => {
  if (!value) {
    return '';
  }
  return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
};

// 获取分页列表数据
export function useList(
  executor,
  {
    field,
    noScroll,
    noFecth,
    modalId,
    selectVisible = true,
    pageSize: defaultPageSize = 10,
    isFilter,
  },
) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    totalCount: 0,
    tableData: [],
  });
  const [pagin, setPagin] = useState({
    currentPage: 1,
    pageSize: defaultPageSize,
  });

  const pagination = useMemo(() => {
    const onPageSizeChange = (pageSize) => {
      setPagin((value) => ({ ...value, pageSize, currentPage: 1 }));
    };
    const onChange = (currentPage) => {
      setPagin((value) => ({ ...value, currentPage }));
    };
    const next = () => {
      setPagin((value) => ({ ...value, currentPage: value.currentPage + 1 }));
    };
    return {
      onChange,
      onPageSizeChange,
      next,
    };
  }, []);

  const search = useCallback(() => {
    pagination.onChange(1);
  }, [pagination]);

  const reset = useCallback(() => {
    field?.reset();
    pagination.onChange(1);
  }, [field, pagination]);

  useEffect(() => {
    if (noFecth) return;
    setLoading(true);
    const q = field?.getValues();
    if (isFilter) {
      const keys = Object.keys(q || {});
      keys.forEach((key) => {
        if (
          q[key] === null ||
          q[key] === undefined ||
          (Array.isArray(q[key]) && q[key].length === 0)
        ) {
          delete q[key];
        }
      });
    }
    const promise = executor(
      {
        ...q,
        currentPage: pagin.currentPage,
        pageSize: pagin.pageSize,
      },
      field,
    );
    promise
      .then(({ tableData = [], paging = {} }) => {
        if (tableData) {
          setData((d) => {
            return {
              tableData:
                pagin.currentPage === 1
                  ? tableData
                  : [...d.tableData, ...tableData],
              totalCount: paging.totalCount,
              isLoadAll: tableData.length < pagin.pageSize,
            };
          });
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executor, pagin, noFecth, field]);

  // 处理触底加载更多
  const handleScroll = useCallback(() => {
    let doc = document.documentElement || document.body;
    if (modalId) {
      doc = document.querySelector(`#${modalId}`);
      if (!(doc instanceof Object)) return;
    }
    const { scrollHeight, clientHeight, scrollTop } = doc;
    if (clientHeight + scrollTop >= scrollHeight - 20) {
      pagination.next();
    }
  }, [pagination, modalId]);

  useEffect(() => {
    if (noScroll) return;
    if (modalId) {
      const doc = document.querySelector(`#${modalId}`);
      if (!(doc instanceof Object)) return;
      if (!data.isLoadAll && !loading && selectVisible) {
        doc.addEventListener('scroll', handleScroll);
      } else {
        doc.removeEventListener('scroll', handleScroll);
      }
    } else if (!data.isLoadAll && !loading) {
      window.addEventListener('scroll', handleScroll);
    } else {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [loading, data.isLoadAll, handleScroll, modalId, noScroll, selectVisible]);

  pagination.currentPage = pagin.currentPage;
  pagination.pageSize = pagin.pageSize;

  return {
    data,
    setData,
    loading,
    search,
    reset,
  };
}

/**
 * 获取数据源label
 * @param {Array} dataSource 数据源
 * @param {String} value 值
 * @returns {String}
 */
export function getDataSourceLabel(dataSource, value, key) {
  let label = '';
  if (dataSource instanceof Array) {
    const index = dataSource.findIndex((item) => item.value === value);
    if (index !== -1) {
      label = dataSource[index].label;
      if (key) {
        label = dataSource[index][key];
      }
    }
  }
  return label;
}

export const longTextRender = (value, line = 2, trigger) => {
  return <LongText content={value} trigger={trigger} line={line} />;
};

// 图片视频配置
export const imageOrVideoStyles = (img, opens) => {
  return {
    key: img.ossKey,
    src: img.imgURL || img.previewUrl,
    title: img.name,
    alt: img.name,
    width: img.width || 90,
    height: 'auto',
    style: {
      margin: '0 8px 8px 0',
      maxHeight: 90,
    },
    onClick: () => {
      if (opens) {
        CnImageViewer.open(opens);
      } else if (img.url) window.location.href = img.url;
    },
  };
};

/**
 * 表单校验同步
 * @param {FormField} field Field实例对象
 * @param {String[]} names 需要校验的表单控件name集合，null则校验全部表单
 * @returns Promise
 */
export function validateField(field, names, repeatConfig) {
  return new Promise((resolve) => {
    field.validate(names, (errors, values) => {
      const repeats = [];
      // 校验重复
      if (repeatConfig instanceof Object) {
        const { repeat, list, editIndex, errorType } = repeatConfig;
        repeat.forEach((item) => {
          const name = list
            .filter((ele, index) => index !== editIndex)
            .filter(
              (ele) =>
                // eslint-disable-next-line eqeqeq
                ele[item.name] == values[item.name] ||
                (typeof values[item.name] === 'string' &&
                  // eslint-disable-next-line eqeqeq
                  ele[item.name] == values[item.name].trim()),
            );
          if (name.length > 0) {
            repeats.push(item);
          }
        });
        if (repeats.length) {
          if (errorType === 'setError') {
            repeats.forEach((item) => {
              field.setError(
                item.name,
                item.error ? item.error : `${item.label} 不允许重复`,
              );
            });
          } else {
            CnMessage.error(
              `${repeats.map((item) => item.label).join('、')} 不允许重复`,
            );
          }
        }
      }
      if (!errors && !repeats.length) {
        resolve(values);
      }
    });
  });
}

// 禁用今天之前日期
export const disabledDateBefore = function (date, mode, value) {
  const currentDate = dayjs(new Date().setHours(0, 0, 0, 0));
  switch (mode) {
    case 'date':
      if (value?.[0]) {
        return date.valueOf() < value[0]?.add(3, 'day')?.valueOf();
      }
      return date.valueOf() < currentDate.valueOf();
    case 'year':
      return date.year() < currentDate.year();
    case 'month':
      return (
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        date.year() * 100 + date.month() <
        currentDate.year() * 100 + currentDate.month()
      );
    default:
      return false;
  }
};

// 机器人PC地址
export const pcRobotUrl = (() => {
  return {
    test: 'https://pre-xl-web.cainiao.com/xlPcPage.html?channel=1000000643',
    pre: 'https://pre-xl-web.cainiao.com/xlPcPage.html?channel=1000000643',
    pro: 'https://xl-web.cainiao.com/xlPcPage.html?channel=1000000643',
  }[env];
})();

// 机器人移动端地址
export const mobileRobotUrl = (() => {
  return {
    test: 'https://page-pre.cainiao.com/cnAIService/online/xl.html?channel=1000000643',
    pre: 'https://page-pre.cainiao.com/cnAIService/online/xl.html?channel=1000000643',
    pro: 'https://page.cainiao.com/cnAIService/online/xl.html?channel=1000000643',
  }[env];
})();
