import { CnLoading, Button, Box } from '@alife/cn-ui';
import { useDebounceEffect, useInViewport } from 'ahooks';
import React, { useCallback, useEffect, useRef, useState, } from 'react';
import { NoAssess } from './NoContent';
import { getPageScrollBox } from '../utils/util';
export function RequestWrapper({ defaultValue, requestData, service, children, lazy = false, errorBoxHeight = 150, debounceWait, ...args }) {
    const wrapperRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [shouldUpdate, setShouldUpdate] = useState(!lazy);
    const [error, setError] = useState();
    const [data, setData] = useState(defaultValue);
    const [inViewport] = useInViewport(wrapperRef, {
        root: getPageScrollBox(),
    });
    const handleGetData = useCallback(async () => {
        try {
            setLoading(true);
            setError(undefined);
            setData(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            await service({
                ...requestData,
                requestId: undefined,
            }));
            // eslint-disable-next-line @typescript-eslint/no-shadow
        }
        catch (error) {
            if (error.errorCode === 'CN10829912004') {
                // 无权限
                setError('NO_ASSESS');
            }
            else {
                setError(error.message);
            }
        }
        finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(requestData)]);
    useDebounceEffect(() => {
        if (shouldUpdate) {
            handleGetData();
        }
    }, [shouldUpdate, handleGetData], { wait: debounceWait ?? 0 });
    useEffect(() => {
        if (inViewport) {
            setShouldUpdate(true);
        }
    }, [inViewport]);
    return (React.createElement("div", { ref: wrapperRef, ...args },
        React.createElement(CnLoading, { visible: loading, style: { width: '100%' } },
            !error && !!data && children(data),
            !!error && (React.createElement(React.Fragment, null, error === 'NO_ASSESS' ? (React.createElement(Box, { justify: 'center', spacing: 16, align: 'center', style: { height: errorBoxHeight } },
                React.createElement(NoAssess, null))) : (React.createElement(Box, { justify: 'center', spacing: 16, align: 'center', style: { height: errorBoxHeight } },
                React.createElement("div", null, error),
                React.createElement(Button, { type: 'primary', text: true, onClick: handleGetData }, "\u70B9\u51FB\u91CD\u8BD5"))))))));
}
