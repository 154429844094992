/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useMemo, useRef, useState } from 'react';
import {
  CnUpload,
  Upload,
  Icon,
  Form,
  Input,
  CnImageViewer,
  CnInput,
} from '@alife/cn-ui';
import { isImg, multipartUpload } from '../utils';
import { locale } from '../../../shared';
import './index.scss';

const defaultValueClone = (data) => {
  if (!data) return [];
  return data.map((item) => ({ ...item }));
};

const ChunkUpload = (props) => {
  const fs = useRef(defaultValueClone(props.defaultValue));
  const [, setForce] = useState({});

  // 上传组件状态
  const statusKey = useMemo(
    () => (props.type === 'img' ? 'state' : 'status'),
    [props.type],
  );

  const handleChange = (value) => {
    const files = value.map((item) => {
      if (item.response instanceof Object) {
        return {
          name: item.response.name,
          ossKey: item.response.ossKey,
          url: item.response.url,
        };
      }
      return item;
    });
    props.onChange && props.onChange(files, props.name, props.formFieldId);
    // 检测是否存在异常和未上传完成的文件
    const uploadingIndex = value.findIndex(
      (item) => item[statusKey] && item[statusKey] !== 'done',
    );
    if (uploadingIndex === -1) {
      props.setUploading && props.setUploading(false);
    }
    setForce({});
  };

  const request = (option) => {
    return multipartUpload(props.fileSize, props.menuCode, props.url, option);
  };
  const commonProps = {
    style: props.style,
    defaultValue: fs.current,
    beforeUpload: () => props.setUploading && props.setUploading(true),
    customRequest: request,
    request,
    className: 'custom-upload',
    // onRemove: handleRemove,
    // onSuccess: handleSuccess,
    // onError: handleError,
    onChange: handleChange,
    name: props.name,
    limit: props.limit,
    accept: props.accept,
    disabled: props.pageState,
    multiple: props.multiple,
    showDownload: !!props.showDownload,
    onPreview: (file) => {
      if (file.url) {
        if (file.url.includes('/#/file-preview')) {
          window.open(file.url);
        } else if (isImg(file.name)) {
          CnImageViewer.open({
            src: file.url,
            hasDownload: false,
          });
        }
      }
    },
  };

  const formItemLayout = useMemo(() => {
    return !props.nano
      ? {
          labelCol: {
            span: props.labelCol,
            fixedSpan: props.labelFixedSpan,
          },
          wrapperCol: {
            span: props.wrapperCol,
          },
        }
      : {};
  }, [props.labelCol, props.labelFixedSpan, props.wrapperCol, props.nano]);

  const uploaderRef = useRef();
  const onPaste = (e) => {
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { files } = e.clipboardData;
    files.length && uploaderRef.current?.uploaderRef?.selectFiles(files);
  };
  const saveUploaderRef = (ref) => {
    if (!ref?.getInstance) return;
    uploaderRef.current = ref.getInstance();
  };

  return (
    <Form.Item
      label={props.label}
      required={props.required}
      {...formItemLayout}
      labelAlign={props.labelAlign}
      labelTextAlign={props.labelTextAlign}
      help={
        props.required && props.requiredErrors ? `${props.label}是必填字段` : ''
      }
      validateState={
        props.required && props.requiredErrors ? 'error' : 'success'
      }
    >
      <Input htmlType='hidden' name={props.name} />
      {props.type === 'img' ? (
        <>
          {props.paste ? (
            <CnInput
              maxLength={0}
              onPaste={onPaste}
              style={{ marginBottom: 12 }}
              placeholder={locale.pastePlaceholder}
            />
          ) : null}
          <Upload.Card
            {...commonProps}
            className='UploadImgCard'
            listType='card'
            ref={saveUploaderRef}
          />
        </>
      ) : (
        <CnUpload
          listType='text'
          fileNameRender={(file) => <span>{file.name}</span>}
          {...commonProps}
          // useDataURL
          // autoUpload={false}
        >
          <div className='upload-bar'>
            <div className='upload-bar-main'>
              <Icon className='upload-bar-icon' type='add' size='xs' />
              <span>{props.mainText}</span>
            </div>
            <div className='upload-bar-assist'>{props.assistText}</div>
          </div>
        </CnUpload>
      )}
    </Form.Item>
  );
};

export const componentName = 'ChunkUpload';
ChunkUpload.displayName = componentName;

ChunkUpload.defaultProps = {
  mainText: '上传附件',
  assistText: '支持所有类型文件',
};
export default ChunkUpload;
