export default {
    login: '登录',
    downloadCenter: '下载中心',
    workerNo: '工号：',
    loginOut: '退出',
    workbench: 'My Vision',
    add: '添加',
    fetchErrorMessage: '操作太频繁, 请稍后再试',
    delete: '删除',
    more: '更多',
    globalMenuTitle: '全部菜单',
    menu: '菜单',
    parkOperation: '园区运营',
    parkConstruction: '园区建设',
    confirm: '确认',
    fetchMenuErrorMessage: '全局菜单查询出错！',
    fetchDingErrorMessage: '查询钉子失败！',
    dingErrorMessage: '钉子失败！',
    help: '问题反馈',
    estateAsst: '小V',
    morning: '上午好',
    afternoon: '下午好',
    dou: '，',
    mao: '：',
    pleaseTickets: '，请在此处提交您的问题～',
    ticketCenter: '问题中心',
    viewTickets: '查看您已反馈过的问题',
    newTicket: '反馈新问题',
    deadline: '期望解决日期',
    problemDescription: '问题描述',
    wordLimit500: '最多输入 500 个字',
    problemUrl: '问题链接',
    problemPhoto: '问题照片',
    cancel: '取消',
    submit: '提交',
    thereAre: '您还有',
    thereAres: '您提出的',
    ticketConfirm: '个问题待确认',
    ticketSolving: '个问题待产技同学解决',
    ticketClosed: '个问题已完结',
    search: '请输入关键字模糊搜索',
    toConfirm: '待确认',
    solving: '解决中',
    closed: '已完结',
    createTime: '反馈时间',
    solution: '解决方案',
    supplyInfo: '补充信息',
    supplyInfoNote: '注意：您补充信息后问题会重新流转到运营/产技同学待解决。',
    supplement: '补充信息',
    photos: '补充照片',
    handler: '处理人',
    ding: '钉ta',
    close: '关闭问题',
    rectified: '已整改',
    closedStatus: '已关闭',
    reopen: '重开',
    empty: '暂无问题',
    agreeInstruction: '确认该问题已解决。',
    closeInstruction: '关闭问题。',
    nodeDTOListEmpty: '您的业务流程尚未配置流程节点，请联系管理员处理后再保存。',
    saveProblemMsg: '保存问题成功！',
    agreeProblemMsg: '确认该问题已完结！',
    returnProblemMsg: '成功补充信息，产技同学解决中，请耐心等待～',
    dingProblemMsg: '钉当前处理人成功！',
    closeProblemMsg: '关闭问题成功！',
    reApplyProblemMsg: '重开问题成功！',
    siteMgmt: '现场管理',
    costMgmt: '成本管理',
    rentMgmt: '招商租赁',
    engineeringMgmt: '工程管理',
    apiErrorTitle: '接口异常',
    apiErrorCode: '错误编码：',
    traceId: 'Trace ID',
    apiErrorTraceID: 'Trace ID：',
    copyApiErrorTraceID: 'Trace ID已复制',
    helpEstateAsst: '求助小V',
    problemUrlPlaceholder: '输入遇到问题的页面链接',
    problemDescriptionPlaceholder: '您可以提报在MyVision工作台上需要关注的指标信息，也可以填报您在系统中遇到的问题。我们将安排专员进行接单处理～',
    traceIdPlaceholder: '若有报错的Trace ID，请输入',
    searchCenter: '搜索',
    pastePlaceholder: '请将图片粘贴至此处以上传',
    product: 'WIN 产品',
    robotTitle: '地网小二机器人',
    robotEstateAsst: '小灵',
    robotPleaseTickets: '，有问题找菜鸟智能客服小灵',
    problemType: '问题类型',
    applicantName: '发起人：',
    reload: '重新加载',
    todo: '我的待办',
    viewAll: '查看所有',
    noToDo: '无未完成待办，',
    viewAllToDo: '查看所有待办',
};
