/* eslint-disable react/prop-types */
import React from 'react';
import { Box, CnIcon } from '@alife/cn-ui';
import './index.scss';
import { preventDefault } from '../shared';

export const selectImg =
  'https://img.alicdn.com/imgextra/i1/O1CN01dXAMby1yK4SMLiY4G_!!6000000006559-55-tps-16-16.svg';
export const noSelectImg =
  'https://img.alicdn.com/imgextra/i1/O1CN01cNSFHz1gte8TOXat1_!!6000000004200-55-tps-16-16.svg';

export default function MenuItem({
  isSelect,
  pinned,
  text,
  icon,
  iconSize,
  iconWidth,
  hasArrow,
  isDing,
  onSelect,
  onTackSelect,
  menuUrl,
}) {
  const renderIcon = () => {
    if (icon) {
      if (typeof icon === 'boolean') {
        return (
          <Box
            className='menu-item-icon'
            style={{ width: 20, marginRight: 10 }}
            justify='center'
            align='center'
          >
            <span>{text.substring(0, 1)}</span>
          </Box>
        );
      } else if (
        typeof icon === 'string' &&
        (icon.startsWith('http') || icon.startsWith('//'))
      ) {
        return (
          <Box
            style={{ width: iconWidth || 18, marginRight: 10 }}
            justify='center'
            align='center'
          >
            <img src={icon} alt='icon' width='100%' />
          </Box>
        );
      } else if (typeof icon === 'string') {
        return (
          <Box
            style={{ width: 20, marginRight: 10, color: '#818CA1' }}
            justify='center'
            align='center'
          >
            <CnIcon type={icon} size={iconSize} />
          </Box>
        );
      }
    }
    return null;
  };

  return (
    <Box
      direction='row'
      className='menu-item-inner'
      style={{ backgroundColor: isSelect ? '#f7f8fa' : 'transparent' }}
      onClick={onSelect}
    >
      {renderIcon()}
      <Box justify='center' className='text'>
        <a
          className='menu-item-text'
          style={{ color: isSelect ? '#3076FC' : '#394252' }}
          href={menuUrl}
          onClick={(e) => preventDefault(menuUrl, e)}
        >
          {text}
        </a>
      </Box>
      {isDing ? (
        <Box className='no-select' justify='center' style={{ marginLeft: 20 }}>
          <div
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              onTackSelect();
            }}
          >
            <img src={pinned ? selectImg : noSelectImg} alt='pinned' />
          </div>
        </Box>
      ) : null}
      <Box style={{ marginLeft: 32 }} justify='center'>
        <div
          className='iconRight'
          style={{
            borderColor: isSelect ? '#3076FC' : '#394252',
            opacity: hasArrow ? 1 : 0,
          }}
        />
      </Box>
    </Box>
  );
}

MenuItem.defaultProps = {
  icon: false,
  onTackSelect: () => {},
  iconSize: 'small',
};
